import Doitxr from "../assets/Corners/Doit(sinfondo).png";
import Phone from "../assets/phone.png";
import aumenta from "../assets/aumenta.png";
import reduce from "../assets/reduce.png";
import mejora from "../assets/mejora.png";
import { BiPlay } from "react-icons/bi";
import desafio from "../assets/ico_desafio.png";
import { FaCheckCircle, FaCaretLeft, FaCaretRight } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import Features from "../components/features";
import InfiniteScroll from "../components/UI/infiniteScroll";
import ScrollAnimation from "react-animate-on-scroll";
import MetaTags from "react-meta-tags";

const About = ({}) => {
  const [indext, setIndex] = useState(0);
  const [question] = useState([
    <div
      className="bg-white rounded-xl md:w-96 w-80 md:p-10 p-8 shadow-lg mx-14"
      key="q1"
    >
      <img
        src={desafio}
        className=" max-h-14 md:max-h-20 mb-2"
        alt="Challenge"
      />
      <p className="font-extrabold text-lg md:text-2xl poppins-bold">
        Mi equipo tiene poca retencion del contenido en las capacidades
        tradicionales...
      </p>
    </div>,
    <div
      className="bg-white rounded-xl md:w-96 w-80 md:p-10 p-8 shadow-lg mx-14"
      key="q2"
    >
      <img
        src={desafio}
        className=" max-h-14 md:max-h-20 mb-2"
        alt="Challenge"
      />
      <p className="font-extrabold text-lg md:text-2xl poppins-bold">
        Nuestros sistemas son realmente aburridos…
      </p>
    </div>,
    <div
      className="bg-white rounded-xl md:w-96 w-80 md:p-10 p-8 shadow-lg mx-14"
      key="q3"
    >
      <img
        src={desafio}
        className=" max-h-14 md:max-h-20 mb-2"
        alt="Challenge"
      />
      <p className="font-extrabold text-lg md:text-2xl poppins-bold">
        El aprendizaje tradicional no permite simular situaciones
      </p>
    </div>,
  ]);
  const [solutions] = useState([
    "En DoitXR el aprendizaje es en un entorno interactivo, estimulado y controlado, ",
    "En DoitXR el aprendizaje es divertido, estimula los sentidos, ",
    "DoitXR permite simular situaciones para practicar habilidades y destrezas ",
  ]);
  const [solutionsAux] = useState([
    "que facilita la absorción, comprensión y aplicación de los nuevos conocimientos.",
    "son elementos de juego que da al usuario derecho a fallar ¨con seguridad¨ y aprender haciendo.",
    "en entornos seguros: toma de decisiones, capacidad de respuesta a situaciones inesperadas, entre otros.",
  ]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervalId = window.setInterval(
      () => setCounter((counter) => counter + 1),
      1000
    );

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setIndex(indext == question.length - 1 ? 0 : indext + 1);
  }, [Math.floor(counter / 10)]);
  return (
    <>
      <MetaTags>
        <title>¿Qué es DoitXR?</title>
        <meta
          name="description"
          content="DoitXR es una plataforma que facilita el proceso de capacitación por medio del aprendizaje inmersivo"
        />
        <meta property="og:title" content="¿Qué es DoitXR?" />
        <meta property="og:image" content="%PUBLIC_URL%/iconDoc.png" />
      </MetaTags>
      <div id="scrollTop" className="" />
      <div className="container pt-40 md:pt-40 pb-0 md:pb-0 ">
        <div className="w-full inline-block">
          <p className="text-center text-2xl poppins-bold">Plataforma de</p>
          <p className="text-center text-5xl py-2 poppins-bold">
            Aprendizaje inmersivo
          </p>
          <p className="text-center text-4xl text-primary poppins-bold">
            WebVR
          </p>
        </div>
        <>
          <img
            src={Doitxr}
            className=" left-0 md:w-full absolute sm:relative"
            alt="DoitXr"
          />
          {/* <div style={{ position: "relative", height: "100%" }}>
              <div
                className="play top-40 md:-top-40 md:left-2/4 cursor-pointer"
                onClick={() => setViewVideo(true)}
              >
                <BiPlay className="play-icon"></BiPlay>
              </div>
            </div> */}
        </>
        )
      </div>

      <div className="bg-about pt-20 sm:pb-20 lg:pb-0 lg:pt-0 mt-64 sm:mt-0 lg:mt-20">
        <div className="container grid md:grid-cols-3 gap-4 ">
          <ScrollAnimation
            animateIn="animate__fadeInDown"
            animateOnce={true}
            className="inline-block align-middle my-auto"
            offset={400}
          >
            <div className="flex justify-center md:justify-start">
              <span className="text-white text-4xl lg:text-5xl poppins-bold ">
                ¿Qué es
                <span className="text-primary"> DoitXR</span>?
              </span>
            </div>
            <p className="text-white text-lg lg:text-xl py-4 poppins-medium text-media-CenterLeft mx-2 lg:mx-0">
              Es una plataforma que facilita el proceso de capacitación por
              medio del aprendizaje inmersivo.
            </p>
            <p className="text-white leading-7 text-sm lg:text-base poppins-medium text-media-CenterLeft">
              Permite al usuario aprender en un escenario virtual interactivo
              que mejora sus habilidades y destrezas en un entorno seguro,
              generando mayor confianza, compromiso y comprensión de los
              conocimientos adquiridos.
            </p>
          </ScrollAnimation>
          <div className="col-span-2">
            <ScrollAnimation
              offset={400}
              animateIn="animate__flipInY"
              animateOnce={true}
            >
              <img src={Phone} className="" alt="Phone" />
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <div className="container py-20">
        <ScrollAnimation
          offset={5000}
          animateIn="animate__zoomInDown"
          animateOnce={true}
        >
          <p className="text-center tracking-widest text-primary poppins-bold text-base">
            ¿D E S A F Í O S?
          </p>
          <p className="text-center text-3xl md:text-4xl poppins-bold py-3">
            Solucionalos con DoitXR
          </p>
          <p className="text-center text-base poppins-medium">
            Principales retos en el modelo de capacitación tradicional
          </p>
        </ScrollAnimation>

        {/* div con scroll lateral */}
        <InfiniteScroll listElements={question} needpy={"py-5"} />

        {/* texto soluciones */}
        <ScrollAnimation
          offset={5000}
          animateIn="animate__fadeIn"
          animateOnce={true}
        >
          <div className="bg-blue-primary rounded-xl w-full lg:w-3/6 p-10 mx-auto shadow-lg mt-80 md:mt-96 h-96 sm:h-60 lg:h-96 xl:h-80">
            <div className="flex pb-4 md:pb-5 justify-center md:justify-start">
              <FaCheckCircle className="text-white text-4xl mr-5" />
              <p className="poppins-bold text-lg lg:text-xl text-white my-auto">
                Solución
              </p>
            </div>
            <div className="text-media-CenterLeft">
              <span className="poppins-bold text-xl lg:text-2xl text-white ">
                {solutions[indext]}
                <span className="poppins-medium">{solutionsAux[indext]}</span>
              </span>
            </div>
          </div>
        </ScrollAnimation>

        {/* compromiso */}

        <div className="mt-14">
          <ScrollAnimation
            offset={5000}
            animateIn="animate__fadeIn"
            animateOnce={true}
          >
            <p className="text-center poppins-bold text-4xl md:text-5xl ">
              Nuestro compromiso
            </p>
            <p className="text-center poppins-medium text-sm md:text-base w-10/12 md:w-4/6 mx-auto py-4 leading-6">
              Transformar el proceso de capacitación corporativa a partir de
              experiencias inmersivas que faciliten y mejoren el proceso de
              aprendizaje del personal de la empresa, dando como resultado un
              aumento en la productividad, una reducción de los accidentes
              laborales y una mejor rentabilidad.
            </p>
          </ScrollAnimation>
        </div>

        {/* MEJORA AUMENTO REDUCE */}
        <ScrollAnimation
          offset={5000}
          animateOnce={true}
          animateIn="animate__slideInDown"
          className="mt-14"
        >
          <div className="container lg:flex justify-center space-y-9 lg:space-y-0 ">
            <div className="bg-white py-4 mx-auto md:w-64 w-52 rounded-2xl shadow-md overflow-hidden">
              <img src={mejora} className="mx-auto w-2/4" alt="DoitXr" />
              <p className="text-base poppins-bold py-2 text-center text-green">
                Mejora
              </p>
              <p className="text-lg poppins-bold text-center w-full">
                la rentabilidad
              </p>
            </div>
            <div className="bg-white py-4  mx-auto md:w-64 w-52 rounded-2xl shadow-md overflow-hidden">
              <img src={aumenta} className="mx-auto w-2/4" alt="DoitXr" />
              <p className="text-base poppins-bold py-2 text-center text-primary">
                Aumenta
              </p>
              <p className="text-lg poppins-bold text-center w-full">
                la productividad
              </p>
            </div>
            <div className="bg-white py-4 md:mx-6 mx-auto md:w-64 w-52 rounded-2xl shadow-md overflow-hidden">
              <img src={reduce} className="mx-auto w-2/4" alt="DoitXr" />
              <p className="text-base poppins-bold py-2 text-center poppins-bold text-red">
                Reduce
              </p>
              <p className="text-lg poppins-bold text-center w-full">
                accidentes laborales
              </p>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      {/* caracteristicas */}
      <div className="">
        <Features noMargin={false} />
      </div>
      <div className="mb-80" />
    </>
  );
};

export default About;
