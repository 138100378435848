import React from "react"; 
import Input from "../components/UI/input";
import launcher from "../assets/Contacto_img.png";
import InputCountries from "../components/UI/inputCountries";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { TiWarning } from "react-icons/ti";
import ReactGA from "react-ga";
import MetaTags from "react-meta-tags";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [disabledButton, setDisabledButton] = useState(false);
  const formRef = useRef(null);
  const [nameError, setNameError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [countryError, setCountryError] = useState(false)

  const toastSended = () => {
    toast("Datos enviados!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  const sendToGA = () => {
    ReactGA.event({
      category: "Button",
      action: "Datos de contacto enviada",
    });
  };

  const onSubmit = (e) => {
    let nameForm = document.getElementById("mce-FNAME").value
    let phoneForm = document.getElementById("mce-PHONE").value
    let emailForm = document.getElementById("mce-EMAIL").value
    let companyForm = document.getElementById("mce-MMERGE8").value    
    let countryForm = document.getElementById("mce-MMERGE11").value
    let messageForm = document.getElementById("mce-MMERGE12").value

    if (nameForm == "") {
      e.preventDefault();
      setNameError(true)
    }
    if (phoneForm == "") {
      e.preventDefault();
      setPhoneError(true)
    }
    if (emailForm == "") {
      e.preventDefault();
      setEmailError(true)
    }
    if (companyForm == "") {
      e.preventDefault();
      setCompanyError(true)
    }
    if (messageForm == "") {
      e.preventDefault();
      setMessageError(true)
    }
    if (countryForm == "null") {
      e.preventDefault();
      setCountryError(true)
    }
  };

  return (
    <>
      <MetaTags>
        <title>Contacto</title>
        <meta name="description" content="Hablemos sobre tu nuevo proyecto" />
        <meta property="og:title" content="Contacto" />
        <meta property="og:image" content="%PUBLIC_URL%/iconDoc.png" />
      </MetaTags>
      <div id="scrollTop" />
      <div className="xl:grid xl:grid-cols-3 w-full">
        <div className="pt-32 xl:col-span-1 gradient-wow">
          <img
            src={launcher}
            className="hidden-contact-desktop w-9/12 sm:max-w-sm mx-auto shake-rocket "
          />
          <div className="xl:ml-60 mt-12 md:mt-0 text-media-CenterLeftXL">
            <p className="poppins-bold text-primary tracking-widest">
              CONTÁCTANOS
            </p>
            <p className="poppins-bold text-3xl">
              Hablemos sobre tu nuevo proyecto
            </p>
          </div>
          <img
            src={launcher}
            className="mt-12 rocket-desktop absolute hidden-contact-mobile shake-rocket "
          />
        </div>
        <div className="bg-white pt-14 xl:pt-32 pb-7 h-full xl:col-span-2">
          <div id="mc_embed_signup">
            <form onSubmit={(e) => { onSubmit(e) }} action="https://doitxr.us10.list-manage.com/subscribe/post?u=b39c8f9247245c319c7ac590d&amp;id=407db458ca&amp;f_id=006a2ee2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="lg:ml-40 lg:mr-80 w-10/12 lg:w-9/12 responsive-mx" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <input type="text" value="DoitXR Contact Form" name="MMERGE13" class="hidden" id="mce-MMERGE13" />
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div>
                    {/* Error label for name*/}
                    {nameError ? <div className="relative">
                      <div className="text-xs poppins-medium text-white bg-primary py-1 px-2 rounded-lg absolute -top-1 right-0 heart flex truncate">
                        <span>
                          <TiWarning className="text-lg mr-1" />
                        </span>
                        <span className="my-auto">{'Campo obligatoio'}</span>
                      </div>
                    </div> : null}
                    {/* Input for name*/}
                    <div class="mc-field-group w-[95%]">
                      <label className="poppins-semibold text-primary px-3 pb-2 tracking-wider " for="mce-FNAME">Nombre</label>
                      <input placeholder="Nombre" className="border-4 rounded-full py-2 px-3 w-full poppins-medium w-full" type="text" name="FNAME" id="mce-FNAME" onChange={(e) => e.value != '' ? setNameError(false) : null} />
                    </div>
                  </div>
                  <div>
                    {/* Error label for phone number*/}
                    {phoneError ? <div className="relative">
                      <div className="text-xs poppins-medium text-white bg-primary py-1 px-2 rounded-lg absolute -top-1 right-0 heart flex truncate">
                        <span>
                          <TiWarning className="text-lg mr-1" />
                        </span>
                        <span className="my-auto">{'Campo obligatoio'}</span>
                      </div>
                    </div> : null}
                    {/* Input for phone number */}
                    <div class="mc-field-group size1of2 w-[95%]">
                      <label className="poppins-semibold text-primary px-3 pb-2 tracking-wider" for="mce-PHONE">Teléfono</label>
                      <input placeholder="Teléfono" className="border-4 rounded-full py-2 px-3 w-full poppins-medium" type="text" name="PHONE" id="mce-PHONE" onChange={(e) => e.value != '' ? setPhoneError(false) : null} />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {/* Error label for Email*/}
                  {emailError ? <div className="relative">
                    <div className="text-xs poppins-medium text-white bg-primary py-1 px-2 rounded-lg absolute -top-1 right-0 heart flex truncate">
                      <span>
                        <TiWarning className="text-lg mr-1" />
                      </span>
                      <span className="my-auto">{'Campo obligatorio'}</span>
                    </div>
                  </div> : null}
                  {/* Input for Email */}
                  <div class="mc-field-group">
                    <label className="poppins-semibold text-primary px-3 pb-2 tracking-wider" for="mce-EMAIL">Email</label>
                    <input placeholder="Email" className="border-4 rounded-full py-2 px-3 w-full poppins-medium required email" type="email" name="EMAIL" id="mce-EMAIL" onChange={(e) => e.value != '' ? setEmailError(false) : null} />
                  </div>
                </div>
                {/* Input for countrys */}
                <InputCountries countryError={countryError} setCountryError={setCountryError}/>
                {/* Error label for Company*/}
                {companyError ? <div className="relative">
                  <div className="text-xs poppins-medium text-white bg-primary py-1 px-2 rounded-lg absolute -top-1 right-0 heart flex truncate">
                    <span>
                      <TiWarning className="text-lg mr-1" />
                    </span>
                    <span className="my-auto">{'Campo obligatoio'}</span>
                  </div>
                </div> : null}
                {/* Input for Company */}
                <div class="mc-field-group">
                  <label className="poppins-semibold text-primary px-3 pb-2 tracking-wider" for="mce-MMERGE8">Empresa</label>
                  <input placeholder="Empresa" className="border-4 rounded-full py-2 px-3 w-full poppins-medium" type="text" name="MMERGE8" id="mce-MMERGE8" onChange={(e) => e.value != '' ? setCompanyError(false) : null} />
                </div>
                <div className="mt-3">
                  {/* Error label for Message*/}
                  {messageError ? <div className="relative">
                    <div className="text-xs poppins-medium text-white bg-primary py-1 px-2 rounded-lg absolute -top-1 right-0 heart flex truncate">
                      <span>
                        <TiWarning className="text-lg mr-1" />
                      </span>
                      <span className="my-auto">{'Campo obligatoio'}</span>
                    </div>
                  </div> : null}
                  {/* Input for message */}
                  <div class="mc-field-group">
                    <label className="poppins-semibold text-primary px-3 pb-2 tracking-wider" for="mce-MMERGE12">Mensaje</label>
                    <textarea
                      onChange={(e) => e.value != '' ? setMessageError(false) : null}
                      placeholder="Mensaje"
                      name="MMERGE12" id="mce-MMERGE12"
                      maxLength="500"
                      rows="5"
                      className="border-4 rounded-3xl py-2 px-3 w-full poppins-medium resize-none"
                    />
                  </div>
                </div>
                <div id="mce-responses" class="clear foot">
                  <div class="response" id="mce-error-response"></div>
                  <div class="response" id="mce-success-response"></div>
                </div>
                <div class="optionalParent">
                  <div class="clear foot">
                    <input className={`rounded-full text-white py-2 poppins-bold text-xl mt-4 w-full button " ${disabledButton ? "bg-gray-500" : " bg-blue-primary "
                      }`} type="submit" value="Enviar" name="subscribe" id="mc-embedded-subscribe" disabled={disabledButton} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
