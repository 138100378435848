import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import About from "./pages/about";
import Presentation from "./pages/presentation";
import PresentationGenially from "./pages/presentacion";
import Cost from "./pages/cost";
import Contact from "./pages/contact";
import Working from "./pages/working";
import ScrollToTop from "./components/lib/scrollToTop";
import "animate.css";
import ReactGA from "react-ga";
import { useEffect, useState } from "react";
import Tutorial from "./pages/tutorial";

export default function App() {
  const [hideAll, setHideAll] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-212728163-1");
    if(window.location.pathname === "/presentacion"){
      setHideAll(true);
    }
    //reportar vistas de pagina
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div>
        { !hideAll ? <Navbar /> : null }
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          {/* <Route path="/cost">
            <Cost />
          </Route> */}
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/working">
            <Working />
          </Route>
          <Route path="/presentation">
            <Presentation />
          </Route>
          <Route path="/tutorial">
            <Tutorial/>
          </Route>
          <Route path="/presentacion">
            <PresentationGenially />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        { !hideAll ? <Footer></Footer> : null }
      </div>
    </Router>
  );
}
