import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { AiOutlineMenu } from "react-icons/ai";

import "./navbar.css";

const Navbar = ({}) => {
  return (
    <div className="fixed top-0 z-50 w-full bg-base select-none">
      <nav
        className="navbar navbar-expand-lg navbar-light "
        aria-label="Eighth navbar example"
      >
        <div className="container">
          <div className="d-flex justify-content-start brand">
            <div>
              <Link to="/">
                <img
                  src={logo}
                  className="img-fluid DoitXrLogo my-2"
                  alt="DoitXr"
                />
              </Link>
            </div>
            <div
              className="d-flex justify-content-end hidden poppins-medium"
              style={{ width: "100%" }}
            >
              <div className="my-auto">
                <div className="buttonNavbar mr-3">
                  <Link to="/contact" className="flex px-3 m-0 justify-center">
                    <div>Pruébalo ahora</div>
                  </Link>
                </div>
              </div>
              <div className="my-auto">
                <button
                  className="navbar-toggler button-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarsExample07"
                  aria-controls="navbarsExample07"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <AiOutlineMenu className="toggler"></AiOutlineMenu>
                </button>
              </div>
            </div>
          </div>

          <div
            className="collapse navbar-collapse poppins-medium"
            id="navbarsExample07"
          >
            <div
              className="flex justify-content-end mobile"
              style={{ width: "100%" }}
            >
              <div className="textNavbar mb-1_res md:mb-0">
                <Link to="/about">
                  <div
                    className="hidden-collapse-desktop"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample07"
                    aria-controls="navbarsExample07"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    ¿Qué es DoitXR?
                  </div>
                  <div className="hidden-collapse-mobile">¿Qué es DoitXR?</div>
                </Link>
              </div>
              {/* <div className="textNavbar">
                <Link to="/working">Aprende más</Link>
              </div> */}
              {/* <div className="textNavbar mb-1_res md:mb-0">
                <Link to="/cost">
                  <div
                    className="hidden-collapse-desktop"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample07"
                    aria-controls="navbarsExample07"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Precio
                  </div>
                  <div className="hidden-collapse-mobile">Precio</div>
                </Link>
              </div> */}
              <div className="textNavbar mb-1_res md:mb-0">
                <Link to="/contact">
                  <div
                    className="hidden-collapse-desktop"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample07"
                    aria-controls="navbarsExample07"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Contacto
                  </div>
                  <div className="hidden-collapse-mobile">Contacto</div>
                </Link>
              </div>
              {/* <div className="textNavbar">
                <Link className="signup" to="/working">
                  Registrarse |{" "}
                </Link>
                <Link className="login" to="/working">
                  Iniciar sesión{" "}
                </Link>
              </div> */}
              <div className="buttonNavbar text-center ">
                <Link
                  to="/contact"
                  className="flex p-butonNavbar m-0 justify-center"
                >
                  <div
                    className="hidden-collapse-desktop"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample07"
                    aria-controls="navbarsExample07"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Pruébalo ahora
                  </div>
                  <div className="hidden-collapse-mobile">Pruébalo ahora</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="line"></div>
    </div>
  );
};

export default Navbar;

{
  /*
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/table-1">Table1</Link>
        </li>
        <li>
          <Link to="/table-2">Table2</Link>
        </li>
      </ul>
    </nav>
*/
}
