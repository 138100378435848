import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./home.css";
import Doitxr from "../../assets/Corners/Doit(sinfondo).png";
import aumenta from "../../assets/aumenta.png";
import reduce from "../../assets/reduce.png";
import mejora from "../../assets/mejora.png";
import piloto from "../../assets/piloto_corner.png";
import easity from "../../assets/essity.png";
import sura from "../../assets/sura.png";
import pozuelo from "../../assets/pozuelo.png";
import pops from "../../assets/pops.png";
import roche from "../../assets/roche.png";
import wallmart from "../../assets/wallmart.png";
import arcelo from "../../assets/arcelormittal.png";
import mabe from "../../assets/mabe.png";
import nutressa from "../../assets/nutressa.png";
import oculus from "../../assets/Oculus_mockup.png";
import phone from "../../assets/phone_mockup.png";
import notebook from "../../assets/notebook_mockup.png";
import tablet from "../../assets/tablet_mockup.png";
import portrait from "../../assets/training/cilindro_1.png";
import brain from "../../assets/training/cerebro.png";
import notebook_training from "../../assets/training/notebook.png";
import cup from "../../assets/training/trofeo.png";
import corner2 from "../../assets/Corners/2.seguridad-y-salud-ocupacional.png";
import corner4 from "../../assets/Corners/4.Entrenamiento-y-educacion.png";
import corner1 from "../../assets/Corners/1.Induccion-empresarial.png";
import corner3 from "../../assets/Corners/3.Manufactura-avanzada.png";
import iconSeccion4 from "../../assets/iconos/1x/icon-seccion-4@2x.png";
import { useEffect, useRef, useState } from "react";
import seccion6 from "../../assets/seccion-6@2x.png";
import seccion7 from "../../assets/seccion-7@3x.png";
import Support from "../support";
import Features from "../features";
import videoDoit from "../../assets/video/DoitHD_Cel.mp4";
import InfiniteScroll from "../UI/infiniteScroll";
import ScrollAnimation from "react-animate-on-scroll";
import { FaYoutube } from "react-icons/fa";
import MetaTags from "react-meta-tags";

const Home = ({}) => {
  const [animation, setAnimation] = useState("");
  const [animationTraining, setAnimationTraining] = useState("");
  const [animationTrainingText, setAnimationTrainingText] = useState(
    "animate__animated animate__fadeIn"
  );
  const [animationCorners, setAnimationCorners] = useState(" animate__fadeIn ");
  const [touch1, setTouch1] = useState(false);
  const [touch2, setTouch2] = useState(false);
  const [touch3, setTouch3] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexCorners, setIndexCorners] = useState(0);
  const [indexTraining, setIndexTraining] = useState(0);
  const [mockups] = useState([phone, tablet, notebook, oculus]);
  const [mockupsText] = useState([
    "Dispositivos móviles",
    "Tablets",
    "Computadoras",
    "Visor VR",
  ]);
  const [training_imgs] = useState([notebook_training, brain, cup]);
  const refCorners = useRef(null);
  const [logos] = useState([
    <div className="mx-12 md:mx-20 w-48" key="easity">
      <img src={easity} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="sura">
      <img src={sura} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="pozuelo">
      <img src={pozuelo} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="pops">
      <img src={pops} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="roche">
      <img src={roche} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="wallmart">
      <img src={wallmart} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="arcelo">
      <img src={arcelo} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="mabe">
      <img src={mabe} alt="" />
    </div>,
    <div className="mx-12 md:mx-20 w-48" key="nutressa">
      <img src={nutressa} alt="" />
    </div>,
  ]);
  const [trainingText] = useState([
    <>
      <strong className="seccion-7-text-1-02 poppins-bold text-4xl text-primary">
        82%
      </strong>
      <br></br>
      <strong className="seccion-7-text-2 poppins-bold text-primary">
        CURSOS DE WEBVR
      </strong>
      <br></br>
      <span className="seccion-7-text-3 text-sm poppins-medium">
        “82% de las empresas que implementan WEBVR indican que los beneficios
        cumplen o superan sus expectativas”
      </span>
      <br></br>
      <br></br>
      <span className="seccion-7-text-3 text-sm poppins-medium">
        -Capgemini Research
      </span>
    </>,
    <>
      <br></br>
      <strong className="seccion-7-text-1-02 poppins-bold text-4xl text-green">
        83%
      </strong>
      <br></br>
      <strong className="seccion-7-text-2 poppins-bold  text-green">
        RETENCIÓN
      </strong>
      <br></br>
      <span className="seccion-7-text-3 text-sm poppins-medium">
        “El 83% de los cirujanos capacitados en WEBVR pudieron realizar la
        cirugía en un entorno de laboratorio con una guía mínima, mientras que
        ninguno de los cirujanos capacitados tradicionalmente pudo hacer lo
        mimdo”.
      </span>
      <br></br>
      <br></br>
      <span className="seccion-7-text-3 text-sm poppins-medium">
        -Jonhnson & Johnson Institute
      </span>
    </>,
    <>
      <br></br>
      <strong className="seccion-7-text-1-02 poppins-bold text-4xl text-purple">
        98%
      </strong>
      <br></br>
      <strong className="seccion-7-text-2 poppins-bold text-purple">
        CURSOS DE WEBVR
      </strong>
      <br></br>
      <span className="seccion-7-text-3 text-sm poppins-medium">
        “82% de las empresas que implementan WEBVR indican que los beneficios
        cumplen o superan sus expectativas”
      </span>
      <br></br>
      <br></br>
      <span className="seccion-7-text-3 text-sm poppins-medium">
        -Capgemini Research
      </span>
    </>,
  ]);
  const [corners] = useState([corner1, corner2, corner3, corner4]);
  const timeoutRef = useRef(null);
  const timeoutRefCorners = useRef(null);

  const resetTimeOut = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };
  const resetTimeOutCorners = () => {
    timeoutRefCorners.current && clearTimeout(timeoutRefCorners.current);
  };

  useEffect(() => {
    resetTimeOut();
    timeoutRef.current = setTimeout(() => {
      setIndex((previndex) =>
        previndex == mockups.length - 1 ? 0 : previndex + 1
      );
      setIndexTraining((indexTraining) =>
        indexTraining == training_imgs.length - 1 ? 0 : indexTraining + 1
      );
    }, 5000);

    setAnimation(" fadeIn ");

    return () => {
      resetTimeOut();
    };
  }, [index]);

  useEffect(() => {
    resetTimeOutCorners();
    timeoutRefCorners.current = setTimeout(
      () =>
        setIndexCorners((indexCorners) =>
          indexCorners == corners.length - 1 ? 0 : indexCorners + 1
        ),
      8000
    );

    return () => {
      resetTimeOutCorners();
    };
  }, [indexCorners]);

  useEffect(() => {
    setAnimationTraining(" animate__zoomInRight ");
    setAnimationTrainingText(" animate__zoomInRight ");
  }, [indexTraining]);

  useEffect(() => {
    setAnimationCorners(" animate__bounceIn ");
    if (indexCorners > 0) {
      refCorners.current.scrollLeft += 250;
    } else {
      refCorners.current.scrollLeft = 0;
    }
  }, [indexCorners]);

  return (
    <>
      <MetaTags>
        <title>DoitXR</title>
        <meta
          name="description"
          content="DoitXR es una plataforma que facilita el proceso de capacitación por medio del aprendizaje inmersivo."
        />
        <meta property="og:title" content="DoitXR" />
        <meta property="og:image" content="%PUBLIC_URL%/iconDoc.png" />
      </MetaTags>
      <div id="scrollTop" />
      <div id="seccion-1">
        <div className="videoContainer mt-16 md:mt-14">
          {/* <div className="overlay" /> */}
          <a
            href="https://www.youtube.com/watch?v=tXuZ9scTnQQ"
            target="_blank"
            className="absolute right-2 top-10 py-2 px-2 btn-start rounded-full border-2 border-gray-500 poppins-medium btn-expand"
          >
            <span className="icon-expand">
              <FaYoutube className=" text-3xl my-auto" />
            </span>
            <span className="my-auto text-expand">Ver Video</span>
          </a>
          <video
            className=""
            typeof="video/mp4"
            src={videoDoit}
            poster={Doitxr}
            controlsList="nodownload"
            muted
            disablePictureInPicture
            autoPlay
            loop
          ></video>
        </div>

        <div className="container mt-12">
          <div className="row-auto text-center">
            <div className="text-center poppins-medium">
              <div className="text-primary text-lg tracking-widest ">
                PLATAFORMA DE
              </div>
              <div className="poppins-bold text-4xl py-3">
                Aprendizaje inmersivo
              </div>
              <div className=" text-2xl">a través de WebVR</div>
            </div>
            <button className="mx-auto mt-12 py-3 px-5 btn-start rounded-full border-2 border-gray-500 poppins-medium shadow-md">
              <Link to="/contact">
                <span>Inicia tu prueba </span>
                <span className="text-green">ahora</span>
              </Link>
            </button>
          </div>

          <div className="col-12 mt-20">
            <div className="flex overflow-y-hidden left-0 w-full  hide-scrollbar">
              <div className="flex-1 bg-white rounded-3xl p-3 mx-3">
                <div className="flex w-64 lg:w-full my-auto">
                  <img
                    src={aumenta}
                    className="w-20 md:w-3/12 mx-2 my-auto"
                    alt="DoitXr"
                  ></img>
                  <div className="mx-2 my-auto ">
                    <strong className="text-1 colorAumenta poppins-bold">
                      Aumenta
                    </strong>
                    <br></br>
                    <span className="text-2 poppins-bold">
                      la productividad
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex-1 bg-white rounded-3xl p-3 mx-3">
                <div className="flex w-64 lg:w-full my-auto">
                  <img
                    src={mejora}
                    className="w-20 md:w-3/12 mx-2 my-auto"
                    alt="DoitXr"
                  ></img>
                  <div className=" my-auto">
                    <strong className="text-1 colorMejora poppins-bold">
                      Mejora
                    </strong>
                    <br></br>
                    <span className="text-2 poppins-bold">la rentabilidad</span>
                  </div>
                </div>
              </div>

              <div className="flex-1 bg-white rounded-3xl p-3 mx-3">
                <div className="flex w-64 lg:w-full my-auto">
                  <img
                    src={reduce}
                    className=" w-20 md:w-3/12 mx-2 my-auto"
                    alt="DoitXr"
                  ></img>
                  <div className=" my-auto">
                    <strong className="text-1 colorReduce poppins-bold">
                      Reduce{" "}
                    </strong>
                    <br></br>
                    <span className="text-2 poppins-bold">
                      los accidentes laborales
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="seccion-2" className="lg:mt-10">
        <div className="container  md:mt-0">
          <div className="row row2 ">
            <div className="col-xl-4 row row2">
              <ScrollAnimation animateIn="animate__zoomIn md:animate__flipInX">
                <div className="col-12 pb-0 text-mediaXL">
                  <div className=" mt-md-2 ">
                    <br></br>
                    <br></br>
                    <span className="seccion-text-1 poppins-bold">
                      Entrenamiento
                    </span>
                    <br></br>
                    <span className="seccion-text-2 poppins-bold">Piloto</span>
                    <br></br>
                    <br></br>
                    <span className="seccion-text-3 md:text-xl poppins-medium">
                      ¿Sabías que una parte esencial del entrenamiento de un
                      piloto, es practicar cientos de horas en un simulador
                      virtual?
                    </span>
                    <br></br>
                    <br></br>
                    <span className="seccion-text-4 text-sm md:text-base poppins-medium">
                      Esto le permite al piloto adquirir y afinar habilidades,
                      que de no tenerlas, pondría en riesgo su vida y la de
                      muchas personas.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="seccion-text-5 text-sm md:text-base poppins-semibold">
                      ¿Has pensado cómo sería tener la mimda experiencia de
                      aprendizaje utilizando para ello tu computadora, tableta o
                      celular?
                    </span>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-xl-8 row row2">
              <div className="mb-20 md:m-0">
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={piloto}
                    className="w-full h-full"
                    alt="DoitXr"
                  ></img>
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-12  hidden-mobile">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>

      <div id="seccion-3" className="pt-5 pb-28 md:pb-52 lg:pb-32 text-center">
        <div className="container">
          <br></br>
          <br></br>
          <ScrollAnimation animateIn="animate__zoomInDown" animateOnce={true}>
            <h1 className="title-s3 text-3xl md:text-4xl poppins-bold">
              Simulador Multiplataforma
            </h1>
            <br></br>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <p className="text-s3 text-xs md:text-lg my-3 poppins-medium w-9/12 md:w-full  mx-auto">
                  DoitXR puede ejecutarse en cualquier computadora, dispositivo
                  móvil o visor de realidad virtual, generando una experiencia
                  más inmersiva.
                </p>
              </div>
            </div>
          </ScrollAnimation>

          <br></br>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            {/* version mobile */}
            <div className="w-full absolute left-0 mockupsText-mobile flex overflow-hidden text-center select-none h-20">
              <div
                className="w-1/4 text-lg md:text-xl py-2 lg:mr-0  cursor-pointer poppins-bold hover:text-green text-link-s3 my-auto break-words"
                onClick={() => index - 1 >= 0 && setIndex(index - 1)}
              >
                {index - 1 == -1 ? "" : mockupsText[index - 1]}
              </div>
              <div className="w-2/4 text-lg md:text-xl py-2 mx-3 lg:mr-0 cursor-pointer poppins-bold hover:text-green text-button-s3 flex items-center justify-center ">
                {mockupsText[index]}
              </div>
              <div
                className="w-1/4 text-lg md:text-xl py-2 lg:mr-0  cursor-pointer poppins-bold hover:text-green text-link-s3 my-auto "
                onClick={() =>
                  index + 1 <= mockupsText.length - 1 && setIndex(index + 1)
                }
              >
                {index + 1 > mockupsText.length - 1
                  ? ""
                  : mockupsText[index + 1]}
              </div>
            </div>
            {/* version desktop */}
            <div className=" mockupsText-desktop left-0 w-auto flex overflow-y-hidden text-center select-none hide-scrollbar">
              <div
                className={`col-lg-3 text-lg md:text-xl py-2 px-5  mr-8 lg:mr-0  cursor-pointer poppins-bold hover:text-green " ${
                  index == 0 ? "text-button-s3 " : "text-link-s3 my-auto "
                }`}
                onClick={() => {
                  setIndex(0);
                }}
              >
                Dispositivos móviles
              </div>
              <div
                className={`col-lg-3 text-lg md:text-xl py-2 px-5 mr-8 lg:mr-0 cursor-pointer  poppins-bold hover:text-green " ${
                  index == 1
                    ? "text-button-s3 flex items-center justify-center "
                    : "text-link-s3 my-auto "
                }`}
                onClick={() => {
                  setIndex(1);
                }}
              >
                Tablets
              </div>
              <div
                className={`col-lg-3 text-lg md:text-xl py-2 px-5 mr-8 lg:mr-0 cursor-pointer poppins-bold hover:text-green  " ${
                  index == 2
                    ? "text-button-s3 flex items-center justify-center "
                    : "text-link-s3 my-auto "
                }`}
                onClick={() => {
                  setIndex(2);
                }}
              >
                Computadoras
              </div>
              <div
                className={`col-lg-3 text-lg md:text-xl py-2 px-5 cursor-pointer poppins-bold hover:text-green " ${
                  index == 3
                    ? "text-button-s3 flex items-center justify-center "
                    : "text-link-s3 my-auto "
                }`}
                onClick={() => {
                  setIndex(3);
                }}
              >
                Visor VR
              </div>
            </div>
          </ScrollAnimation>
          <br></br>
          <div style={{ position: "relative" }}>
            <div className="hidden-mobile">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
            <img
              src={mockups[index]}
              className={`Oculus mt-12 md:mt-40 lg:mt-20 max-w-md md:max-w-3xl w-screen "${animation}`}
              alt="DoitXr"
              onAnimationEnd={() => setAnimation("")}
            />
          </div>
        </div>
        <div className="hidden-desktop mb-20">
          <br></br>
          <br></br>
        </div>
        <br></br>
        <br></br>
      </div>
      <div id="seccion-4" className="container md:mt-20">
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <ScrollAnimation animateIn="animate__zoomIn" animateOnce={true}>
          <div className="row row2 mt-32">
            <div className="col-lg-4">
              <div className="text mt-md-5">
                <span className="seccion-4-text-1 poppins-bold text-4xl">
                  DoitXR
                </span>
                <br></br>
                <span className="seccion-4-text-2 poppins-bold poppins-bold text-3xl lg:text-4xl ">
                  Muchos sectores,
                  <br />
                  una solución.
                </span>
                <br></br>
                <div
                  ref={refCorners}
                  className="grid grid-flow-col auto-cols-max lg:inline-block  overflow-y-hidden mt-12 lg:mb-28 hide-scrollbar"
                >
                  <span
                    className={`seccion-4-text-4 mr-8 hover:text-primary poppins-semibold cursor-pointer " ${
                      indexCorners == 0 && " text-primary "
                    } `}
                    onClick={() => {
                      setIndexCorners(0);
                    }}
                  >
                    01. Inducción empresarial
                  </span>
                  <br></br>
                  <span
                    className={`seccion-4-text-4 mr-8 hover:text-primary poppins-semibold cursor-pointer " ${
                      indexCorners == 1 && " text-primary "
                    } `}
                    onClick={() => {
                      setIndexCorners(1);
                    }}
                  >
                    02. Seguridad / Salud Ocupacional
                  </span>
                  <br></br>
                  <span
                    className={`seccion-4-text-4 mr-8 hover:text-primary poppins-semibold cursor-pointer " ${
                      indexCorners == 2 && " text-primary "
                    } `}
                    onClick={() => {
                      setIndexCorners(2);
                    }}
                  >
                    03. Manufactura avanzada
                  </span>
                  <br></br>
                  <span
                    className={`seccion-4-text-4 hover:text-primary poppins-semibold cursor-pointer " ${
                      indexCorners == 3 && " text-primary "
                    } `}
                    onClick={() => {
                      setIndexCorners(3);
                    }}
                  >
                    04. Entrenamiento y educación
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-8 ">
              <div className="relative">
                {/* {touch1 && (
                  <div className="card-seccion-4-mini touch-seccion-4-1-c text-xs z-10">
                    <span className="poppins-bold">
                      Podrá generar demostraciones{" "}
                    </span>
                    <span className="poppins-medium">
                      de los próximos proyectos en construcción.
                    </span>
                  </div>
                )}

                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={300}
                  animateOnce={true}
                >
                  <div
                    className="btn touch-seccion-4-1 heart z-10"
                    onClick={() => {
                      setTouch1(!touch1);
                    }}
                  >
                    <img src={iconSeccion4} width="40" alt="DoitXr"></img>
                  </div>
                </ScrollAnimation>

                {touch2 && (
                  <div className="card-seccion-4 touch-seccion-4-2-c text-xs z-10">
                    <span className="poppins-medium">
                      Su demostración{" "}
                      <span className="poppins-bold">se puede </span>
                    </span>
                    <span className="poppins-bold">
                      {" "}
                      ejecutar en cualquier computadora, dispositivo móvil o
                      visores de realidad virtual,{" "}
                    </span>
                    <span className="poppins-medium">
                      generando una experiencia más inmersiva.
                    </span>
                  </div>
                )}
                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={600}
                  animateOnce={true}
                >
                  <div
                    className="btn touch-seccion-4-2 heart z-10"
                    onClick={() => {
                      setTouch2(!touch2);
                    }}
                  >
                    <img src={iconSeccion4} width="40" alt="DoitXr"></img>
                  </div>
                </ScrollAnimation>

                {touch3 && (
                  <div className="card-seccion-4 touch-seccion-4-3-c text-xs z-10">
                    <span className="poppins-medium">
                      Crear demostraciones impresionantes que permitan a los
                      usuarios explorar desde cualquier ángulo,{" "}
                    </span>
                    <span className="poppins-bold">
                      además de probar colores, tamaños y diseños.
                    </span>
                  </div>
                )}

                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={900}
                  animateOnce={true}
                >
                  <div
                    className="btn touch-seccion-4-3 heart z-10"
                    onClick={() => {
                      setTouch3(!touch3);
                    }}
                  >
                    <img src={iconSeccion4} width="40" alt="DoitXr"></img>
                  </div>
                </ScrollAnimation> */}
                <img
                  src={corners[indexCorners]}
                  alt="DoitXR"
                  className={`img-fluid animate__animated " ${animationCorners} "`}
                  onAnimationEnd={() => {
                    setAnimationCorners("animate__bounceOut animate__delay-8s");
                  }}
                />
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      <div id="seccion-5" className="">
        {/* caracteristicas */}
        <Features />
      </div>

      <div id="seccion-6" className="pt-48">
        <div className="container">
          <ScrollAnimation animateIn="animate__jackInTheBox" animateOnce={true}>
            <div className="lg:flex lg:flex-row-reverse ">
              <div className="col-lg-7 mb-4 md:mt-24 lg:mt-0">
                <img src={seccion6} className="w-full float" alt="DoitXr"></img>
              </div>
              <div
                className="col-lg-5 pe-md-5 text pb-40 flex flex-col justify-center"
                style={{ verticalAlign: "center" }}
              >
                <strong className="seccion-5-text-1 text-2xl md:text-3xl poppins-bold">
                  Crea nuevas
                </strong>
                <strong className="seccion-5-text-1 text-2xl md:text-3xl poppins-bold">
                  capacitaciones con
                </strong>
                <strong className="seccion-5-text-2 text-4xl md:text-6xl poppins-bold">
                  Gamificacion
                </strong>
                <span className="seccion-5-text-3 text-sm md:text-lg poppins-medium">
                  Como administrador podrás definir y asignar un puntaje por
                  lecciones completadas, por exámenes, quizzes, otorgar
                  insignias, niveles y un tablero con el ranking de líderes.
                </span>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div id="seccion-7" className="">
        <div className="container">
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            className="text-center md:w-2/4 mx-auto mt-20"
          >
            <div>
              <strong className="seccion-7-text-1 poppins-bold text-3xl md:text-5xl">
                ¿Por qué hacer los entrenamientos{" "}
                <strong className="color-green poppins-bold text-3xl md:text-5xl">
                  WebVR?
                </strong>
              </strong>
            </div>
          </ScrollAnimation>

          {/* SECCION MOBILE ENTRETENIMIENTO - IMAGENES */}
          <div className="seccion-7-desktop relative">
            <img
              src={training_imgs[indexTraining]}
              alt="DoitXr"
              className={`w-full md:w-9/12 md:mx-auto animate__animated " ${animationTraining} "`}
              onAnimationEnd={() => {
                setAnimationTraining("animate__zoomOutLeft animate__delay-3s");
              }}
            />
            <img src={portrait} className="absolute top-2/4 center-absolute " />
          </div>
        </div>
        {/* SECCION DESKTOP DE ENTRENAMIENTO */}
        <div className="seccion-7-mobile" style={{ position: "relative" }}>
          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            className="content-1 text-end"
          >
            <strong className="seccion-7-text-1-02 poppins-bold text-4xl text-primary">
              82%
            </strong>
            <br></br>
            <strong className="seccion-7-text-2 poppins-bold text-lg text-primary">
              CURSOS DE WEBVR
            </strong>
            <br></br>
            <span className="seccion-7-text-3 poppins-medium">
              “82% de las empresas que implementan WEBVR indican que los
              beneficios cumplen o superan sus expectativas”
            </span>
            <br></br>
            <br></br>
            <span className="seccion-7-text-3 poppins-medium">
              -Capgemini Research
            </span>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            className="content-2 text-start"
          >
            <strong className="seccion-7-text-1-02 poppins-bold text-4xl text-purple">
              98%
            </strong>
            <br></br>
            <strong className="seccion-7-text-2 poppins-bold text-lg text-purple">
              CURSOS DE WEBVR
            </strong>
            <br></br>
            <span className="seccion-7-text-3 poppins-medium">
              “82% de las empresas que implementan WEBVR indican que los
              beneficios cumplen o superan sus expectativas”
            </span>
            <br></br>
            <br></br>
            <span className="seccion-7-text-3 poppins-medium">
              -Capgemini Research
            </span>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="animate__fadeIn"
            animateOnce={true}
            className="content-3  lg:-bottom-28 xl:-bottom-20 2xl:-bottom-9 text-start"
          >
            <strong className="seccion-7-text-1-02 poppins-bold text-4xl text-green">
              83%
            </strong>
            <br></br>
            <strong className="seccion-7-text-2 poppins-bold text-lg text-green">
              RETENCIÓN
            </strong>
            <br></br>
            <span className="seccion-7-text-3 poppins-medium">
              “El 83% de los cirujanos capacitados en WEBVR pudieron realizar la
              cirugía en un entorno de laboratorio con una guía mínima, mientras
              que ninguno de los cirujanos capacitados tradicionalmente pudo
              hacer lo mimdo”.
            </span>
            <br></br>
            <br></br>
            <span className="seccion-7-text-3">
              -Jonhnson & Johnson Institute
            </span>
          </ScrollAnimation>

          {/* IMAGEN SECCION 7 */}
          <ScrollAnimation animateOnce={true} animateIn="animate__jackInTheBox">
            <img
              className="mt-20"
              src={seccion7}
              style={{ width: "100%" }}
              alt="DoitXr"
            ></img>
          </ScrollAnimation>
          <div className="hidden-mobile">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>

        {/* SECCION MOBILE DE ENTRENAMIENTO */}

        <div className="seccion-7-desktop mb-20 ">
          <div className="container px-5">
            <ScrollAnimation
              animateIn="animate__fadeIn"
              animateOnce={true}
              className="text-center"
            >
              <div
                className={`animate__animated " ${animationTrainingText} "`}
                onAnimationEnd={() =>
                  setAnimationTrainingText(
                    "animate__zoomOutLeft animate__delay-3s"
                  )
                }
              >
                {trainingText[indexTraining]}
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>

      <div className="container mt-20">
        <ScrollAnimation
          animateIn="animate__fadeIn"
          animateOnce={true}
          className="pb-4 text-center"
        >
          <p className="text-center py-5 text-lg md:text-xl poppins-bold tracking-wider">
            QUIENES CONFIAN EN DOIT XR
          </p>
          <InfiniteScroll listElements={logos} />

          <button className="mx-auto my-16 py-3 px-5 btn-start rounded-full border-2 border-gray-500 poppins-medium shadow-md">
            <Link to="/contact">
              <span>Inicia tu prueba </span>
              <span className="text-green">ahora</span>
            </Link>
          </button>
        </ScrollAnimation>
      </div>

      {/* <Support /> */}
    </>
  );
};

export default Home;
