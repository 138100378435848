import React, { useEffect, useState } from "react";
import { MdDashboard, MdQueryBuilder, MdVideoLibrary } from "react-icons/md";
import {
  FaVrCardboard,
  FaStar,
  FaFileAlt,
  FaBookReader,
  FaCertificate,
  FaCalendarAlt,
  FaChartBar,
  FaPalette,
  FaUser,
  FaComments,
} from "react-icons/fa";
import dashboard from "../assets/Caracteristicas/1.Dashboard.png";
import reports from "../assets/Caracteristicas/2.reportes.png";
import certificado from "../assets/Caracteristicas/13.certificado.png";
import videoTrivia from "../assets/Caracteristicas/3.videotrivia.png";
import webvr from "../assets/Caracteristicas/4.webvr.png";
import dinamicTrivia from "../assets/Caracteristicas/5.triviadinamica.png";
import presentations from "../assets/Caracteristicas/6.presentaciones.png";
import evaluations from "../assets/Caracteristicas/7.evaluacion.png";
import events from "../assets/Caracteristicas/8.eventos.png";
import personalization from "../assets/Caracteristicas/9.personalizacion.png";
import courses from "../assets/Caracteristicas/10.curso.png";
import users from "../assets/Caracteristicas/11.usuarios.png";
import chat from "../assets/Caracteristicas/12.Chat.png";
import { BiSlideshow } from "react-icons/bi";
import { useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Features = (noMargin) => {
  const [index, setIndex] = useState(0);
  const [imgList] = useState([
    dashboard,
    reports,
    videoTrivia,
    evaluations,
    webvr,
    dinamicTrivia,
    presentations,
    courses,
    certificado,
    events,
    personalization,
    chat,
    users,
  ]);

  const [iconList] = useState([
    <MdDashboard className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaChartBar className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <MdVideoLibrary className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaStar className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaVrCardboard className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaFileAlt className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <BiSlideshow className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaBookReader className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaCertificate className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaCalendarAlt className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaPalette className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaComments className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
    <FaUser className="md:w-6 md:h-6 w-7 h-7 icon-feature lg:mx-auto" />,
  ]);
  const [descriptionList] = useState([
    <>
      <MdDashboard className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Dashboard</p>
      <p className="poppins-medium text-sm md:text-base">
        Encuentra en un solo lugar toda la información relevante de los cursos,
        usuarios, evaluaciones y estadísticas.
      </p>
    </>,
    <>
      <FaChartBar className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Reportes</p>
      <p className="poppins-medium text-sm md:text-base text-left">
        •Progreso de cursos, grupos y usuarios.
        <br />
        •Cantidad de usuarios asignados, tanto activos como inactivos.
        <br />
        •Duración promedio de sesiones.
        <br />
        •Desglose de resultados por evaluación. •Bitácora de movimientos
      </p>
    </>,
    <>
      <MdVideoLibrary className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Videos Interactivos</p>
      <p className="poppins-medium text-sm md:text-base text-left">
        Con nuestra plataforma podrás definir en donde deseas detener el video e
        interactuar con el usuario mediante una o varias trivias.
        <br />
        <br />
        <div className="text-left">
          Tipos de trivias:
          <br />· Selección única
          <br />
          · Selección múltiple
          <br />
          · Asocie
          <br />· Complete
        </div>
      </p>
    </>,
    <>
      <FaStar className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Evaluaciones</p>
      <div className="poppins-medium text-sm md:text-base">
        <div className="text-left">
          • Más de 10 tipos de evaluaciones distintas.
          <br />• Evaluaciones de práctica, calificación automática,
          calificación manual y encuestas.
          <br />• Nota mínima, control de tiempo, control de intentos, control
          de progreso.
        </div>
      </div>
    </>,
    <>
      <FaVrCardboard className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">WebVR</p>
      <p className="poppins-medium text-sm md:text-base ">
        Experiencias 100% funcionales en smartphones, tablets, computadoras (Mac
        y PC) o desde un visor de <br />
        Realidad Virtual.
      </p>
    </>,
    <>
      <FaFileAlt className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Experiencias prediseñadas</p>
      <p className="poppins-medium text-sm md:text-base ">
        Más de 10 experiencias prediseñadas listas para personalizarlas <br />
        con tu diseño o logo.
      </p>
    </>,
    <>
      <BiSlideshow className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Presentaciones interactivas</p>
      <p className="poppins-medium text-sm md:text-base">
        Permite realizar presentaciones, agregando imágenes, videos, textos,{" "}
        <br />
        con interacciones básicas para la creación de contenido.
      </p>
    </>,
    <>
      <FaBookReader className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Creador de cursos</p>
      <div className="poppins-medium text-sm md:text-base text-left">
        Crea tus propias lecciones:
        <br />
        <br />
        <div className="text-left">
          • Vídeos
          <br />• Texto enriquecido
          <br />• Actividades Genially
          <br />• Archivos descargables
          <br />• Audios
          <br />• PDF
          <br />• Evaluaciones
          <br />• Juegos, entre otros...
        </div>
      </div>
    </>,
    <>
      <FaCertificate className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Certificados</p>
      <p className="poppins-medium text-sm md:text-base">
        Envío automático del certificado al concluir satisfactoriamente el
        curso.
        <br />
        <br />
        Verificación. Cada certificado contará con un número de serie único que
        permitirá validar el certificado en línea.
      </p>
    </>,
    <>
      <FaCalendarAlt className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Eventos</p>
      <div className="poppins-medium text-sm md:text-base text-left">
        • Organiza clases sincrónicas o asincrónicas.
        <br />
        • Crea invitaciones por participante, estudiante o curso. <br />•
        Permite conectarse con Google Meet, Zoom y Teams.
      </div>
    </>,
    <>
      <FaPalette className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Personalización</p>
      <p className="poppins-medium text-sm md:text-base text-left">
        Puedes hacer las combinaciones que gustes. Cambia colores y fondos,
        coloca un logo o personaliza la URL a tu gusto.
      </p>
    </>,
    <>
      <FaComments className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Chat</p>
      <p className="poppins-medium  text-sm md:text-base">
        Posee un sistema de mensajería que comunica a usuarios con su
        instructor, y a instructores con sus usuarios <br />
        por curso o por grupo.
      </p>
    </>,
    <>
      <FaUser className="w-16 h-16 text-primary mx-auto" />
      <p className="poppins-bold text-3xl py-2">Perfil y roles</p>
      <p className="poppins-medium text-sm md:text-base">
        Crea distintos tipos de perfiles y roles según las necesidades de tu
        organización.
        <br />
        <br /> Concede, modifica o elimina <br /> privilegios según lo
        necesites.
      </p>
    </>,
  ]);

  const [animation, setAnimation] = useState("");
  const [animationAux, setAnimationAux] = useState("");
  const refScroll = useRef(null);
  const timeoutRef = React.useRef(null);

  const resetTimeOut = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    resetTimeOut();
    timeoutRef.current = setTimeout(
      () => setIndex((index) => (index == iconList.length - 1 ? 0 : index + 1)),
      10000
    );

    return () => {
      resetTimeOut();
    };
  }, [index]);

  useEffect(() => {
    document.getElementById(`${index}`).classList.add("swing");
    setAnimation(" scale-up-top ");
    setAnimationAux(" fadeIn ");
    if (index > 4) {
      refScroll.current.scrollLeft += 80;
    } else {
      refScroll.current.scrollLeft = 0;
    }
  }, [index]);

  return (
    <div className="bg-blue-primary pt-20 pb-32">
      <div className="container">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <p className="text-white text-center text-4xl poppins-bold">
            Características principales
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true}>
          <p className="text-white text-center text-base md:text-lg py-7 w-full md:w-4/6 mx-auto poppins-medium">
            DoitXR te brinda todas las características de un Learning Management
            System (LMS) con la potencia de nuestros simuladores WebVR.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <div
            ref={refScroll}
            className="flex w-full xl:w-3/4 m-auto lg:justify-center overflow-y-hidden hide-scrollbar"
          >
            {iconList.map((iconOnList, i) => (
              <div
                className={`flex cursor-pointer rounded-full h-11 w-11 text-sm md:text-base shadow-sm mx-3 " ${
                  index == i ? "bg-white text-primary" : "bg-blue-400 text-icon"
                }`}
                onClick={() => setIndex(i)}
                key={i}
                id={i}
              >
                {iconOnList}
              </div>
            ))}
          </div>
        </ScrollAnimation>
        <div className="container relative ">
          <div className="h-96"></div>
          <img
            className={`absolute left-0 top-0 "${animation}`}
            src={imgList[index]}
            alt=""
            onAnimationEnd={() => setAnimation("")}
          />
          <div
            className={`absolute bg-white shadow-bigger p-4 content-center text-center w-full lg:max-w-sm rounded-2xl top-60 right-0 sm:top-72 md:top-96 lg:top-40 lg:right-0  " ${animationAux}`}
            onAnimationEnd={() => setAnimationAux("")}
          >
            {descriptionList[index]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
