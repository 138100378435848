import React from "react";

const InfiniteScroll = ({ listElements, needpy }) => {
  return (
    <div className="container relative w-full mb-20">
      <div
        className={`scroll-bar absolute w-full right-0 lg:w-full flex overflow-hidden " ${
          needpy && needpy
        }`}
      >
        <div className="scroller flex">
          {listElements.map((element) => element)}
        </div>

        <div className="scroller flex">
          {listElements.map((element) => element)}
        </div>
      </div>
    </div>
  );
};

export default InfiniteScroll;
