import { useEffect, useRef } from 'react';

export default function Tutorial() {

  const main = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      fitStageIntoParentContainer(window, main.current);

      window.addEventListener('resize', () => {
        fitStageIntoParentContainer(window, main.current);
      });
    }
  }, []);

  const fitStageIntoParentContainer = (window, element) => {
    // now we need to fit stage into parent
    let containerWidth = window.innerWidth;
    let containerHeight = window.innerHeight;
    // to do this we need to scale the stage
    let scale = Math.min(containerWidth / 16, containerHeight / 9);

    element.style.width = `${16 * scale}px`;
    element.style.height = `${9 * scale}px`;
  }

  return (
    <div className='genially-container'>
      <div className='genially-bg'>
        <div ref={main}>
          <iframe style={{width: '100%', height: '100%', border: 'none', margin: 'auto'}} src='https://view.genial.ly/6227d773853e2f0012f12b05'/>
        </div>
        <div className='msg'>
          <p>Gire el dispositivo para ver el tutorial</p>
        </div>
      </div>
      
    </div>
  )
}
