import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./footer.css";
import logo from "../../assets/logo.png";
import { BiChevronDown } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import {
  AiFillYoutube,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";

import { FaFacebook } from "react-icons/fa";

const Footer = ({}) => {
  return (
    <div className="">
      <footer
        className="text-center text-xs-start text-dark"
        style={{ backgroundColor: "white" }}
      >
        <section className="">
          <div className="container text-center text-md-start mt-0">
            {/* <div className="row mt-0">
              <div className=" col-xs-1 col-md-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold title-F poppins-bold text-lg">
                  DoitXR
                </h6>
                <br></br>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Sobre nosotros
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Plataforma
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Precios
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Contacto
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Registrarse
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Iniciar sesión
                  </Link>
                </p>

                <p className="py-2 ">
                  <Link
                    to="/"
                    className="text-F-links flex footer-link poppins-bold justify-center md:justify-start"
                  >
                    Más de DoitXR{" "}
                    <BiChevronDown className="text-primary text-2xl ml-4" />
                  </Link>
                </p>
              </div>

              <div className="col-xs-1 col-md-3 mx-auto mb-4 pt-6 md:pt-0">
                <h6 className="text-uppercase fw-bold title-F poppins-bold text-lg">
                  Sectores
                </h6>
                <br></br>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Industrial
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Servicios
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Inmobiliaria y construcción
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Educación
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Salud y medicina
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Otras más
                  </Link>
                </p>

                <p className="py-2">
                  <Link
                    to="/"
                    className="text-F-links flex footer-link poppins-bold justify-center md:justify-start"
                  >
                    Más sectores{" "}
                    <BiChevronDown className="text-primary ml-4 text-2xl"></BiChevronDown>
                  </Link>
                </p>
              </div>

              <div className="col-xs-1 col-md-3 mx-auto mb-4 pt-6 md:pt-0">
                <h6 className="text-uppercase fw-bold title-F poppins-bold text-lg">
                  Características
                </h6>
                <br></br>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Escenarios webVR
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Multiplataforma
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Videos interactivos
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Creador de cursos
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Perfiles y roles
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Evaluaciones
                  </Link>
                </p>

                <p className="py-2">
                  <Link
                    to="/"
                    className="text-F-links flex footer-link poppins-bold justify-center md:justify-start"
                  >
                    Más características{" "}
                    <BiChevronDown className="text-primary text-2xl ml-4"></BiChevronDown>
                  </Link>
                </p>
              </div>

              <div className="col-xs-1 col-md-3 mx-auto mb-4 pt-6 md:pt-0">
                <h6 className="text-uppercase fw-bold title-F poppins-bold text-lg">
                  Actualizaciones
                </h6>
                <br></br>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Nuevas integraciones sobre…
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Creación de nuevos juegos…
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Nuevas integraciones sobre…
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Creación de nuevos juegos…
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Nuevas integraciones sobre…
                  </Link>
                </p>
                <p className="py-2">
                  <Link to="/" className="text-F-links poppins-medium">
                    Creación de nuevos juegos…
                  </Link>
                </p>

                <p className="py-2">
                  <Link
                    to="/"
                    className="text-F-links flex footer-link poppins-bold justify-center md:justify-start"
                  >
                    Ir a actualizaciones{" "}
                    <FaArrowRight className="text-primary ml-4 my-auto"></FaArrowRight>
                  </Link>
                </p>
              </div>
            </div> */}
          </div>
        </section>
        <hr></hr>
        <section
          className="md:flex justify-content-between pb-4 pt-4 container"
          style={{ backgroundColor: "white" }}
        >
          <div className="">
            <img
              src={logo}
              className="img-fluid DoitXrLogo mx-auto"
              alt="DoitXr"
            ></img>
          </div>

          <div className="text-center md:text-right">
            <div className="flex flex-row-reverse justify-center md:justify-start">
              <a
                href="https://www.youtube.com/channel/UCC5icPOVKrDaYBOfJxgQ3_g"
                target="_blank"
                className="m-1"
              >
                <AiFillYoutube className="social-media text-6xl hover:text-primary"></AiFillYoutube>
              </a>
              <a
                href="https://www.linkedin.com/company/doitxr"
                target="_blank"
                className="m-1"
              >
                <AiFillLinkedin className="social-media text-6xl hover:text-primary"></AiFillLinkedin>
              </a>
              {/* <Link to="/" className="m-1">
                <FaFacebook className="social-media"></FaFacebook>
              </Link>
              <Link to="/" className="m-1">
                <AiOutlineInstagram className="social-media"></AiOutlineInstagram>
              </Link> */}
            </div>
            {/* <span className="identidad-1 mt-1 poppins-medium text-right">
              2021 Doit XR{" "}
            </span>
            <br></br>
            <Link to="/" className="identidad-2 poppins-medium">
              Seguridad | Términos y condiciones
            </Link> */}
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Footer;
