import React, { useState } from "react";

const Working = () => {
  return (
    <div className="container py-40 gradient-wow">
      <p className="text-3xl poppins-bold text-center">
        Estamos trabajando en ello para brindarte la mejor experiencia!
      </p>
    </div>
  );
};

export default Working;
